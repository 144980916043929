import { Component } from 'react'
import './banner.scss'
import { Link } from 'react-router-dom'
import logo from '../../../Constants/logo'



export default class Banner extends Component {
  render() {
    return (
      <div className='banner' style={{backgroundImage: `url(${logo.banner})`}}>
        <div className="content">
          <div className="left">
            <h1 data-aos='fade-down' data-aos-duration='1800'>Archedu.uz</h1>
            <p data-aos='fade-down' data-aos-duration='2200'>Qurilish sohasidagi bilimlarni biz orqali mukammal darajada o'rganing va sohaning yetuk mutaxassisiga aylaning !</p>
            <Link to={'/login'} className='btn'>Boshlash</Link>
          </div>
        </div>
      </div>
    )
  }
}