const links = [
    {
      url: '/courses',
      title: 'Kurslar'
    },
    {
      url: '/products',
      title: 'Kerakli dasturlar'
    },
    
  ]

  export default (links);
