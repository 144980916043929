import './header.scss'
import { Link } from 'react-router-dom'
import links from '../../../Constants/linkers'
import logo from '../../../Constants/logo'

const Header = () => {
    return (
      <div className='header'>
        <div className="nav">
          <ul>
            <Link to={'/'}><img src={logo.logo} alt="logo" /></Link>
            {links.map((key, index) => (
              <li key={index}><Link className='links' to={key.url}>{key.title}</Link></li>
            ))}
              <li ><a href="https://t.me/ArchGallery.uz">Portfolio</a></li>
          </ul>
        </div>
      </div>
    )
  }

  export default Header;
