import { Component } from 'react'
import './products.scss'
import Header from '../Home/Header/Header'
import Footer from '../Home/Footer/Footer'

export default class Products extends Component {
  render() {
    return (
      <div>
        <Header />
        <Footer />
      </div>
    )
  }
}
