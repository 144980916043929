import { Component } from 'react'
import './registration.scss'

export default class Registration extends Component {
  render() {
    return (
      <div>Registration</div>
    )
  }
}
