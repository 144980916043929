import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './Components/Home/Home';
import OnlineCourse from './Components/Courses/Onlinecourse/OnlineCourse';
import Registration from './Components/User/Registration/Registration';
import Login from './Components/User/Login/Login';
import Products from './Components/Products/Products';

function App() {
  return (
    <div className="App" data-aos='fade-in' data-aos-duration='1000'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<OnlineCourse />} />
        <Route path='/products' element={<Products />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
