import './categories.scss'
import { Box } from '@mui/material'
import ArchitectureIcon from '@mui/icons-material/Architecture';
import BrushIcon from '@mui/icons-material/Brush';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BuildIcon from '@mui/icons-material/Build';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const Categories = ()=> {
    return (
      <div className='categories'>
        <Box className="content">
            <div className="box">
              <div className="left" style={{backgroundColor: '#973131'}} >
                <h1> <ArchitectureIcon style={{fontSize: '120px'}}/></h1>
                <p>Arxitektura kurslarimizda siz binolarning arxitekturaviy loyihasi, xonalar bo’linishi, bo’sh yerga binoni loyihalashtirish kabi bilimlarga ega bo’lasiz.</p>
              </div>
              <div className="right" style={{backgroundImage: `url(comp.jpg)`, backgroundSize: 'cover'}} data-aos='fade-in' data-aos-duration='2000'></div>
            </div>

            <div className="box">
              <div className="right" data-aos='fade-in' data-aos-duration='2000'  style={{backgroundImage: `url(intex2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
              <div className="left" style={{backgroundColor: '#973131'}}>
              <h1> <BrushIcon style={{fontSize: '120px'}}/></h1>
              <p>Dizayn va modellashtirish kurslarimizda siz binolar ichki interyeri, tashqi dizayni shuningdek modellar yasash bilimlarini egallaysiz.</p>
              </div>
            </div>

            <div className="box">
              <div className="left" style={{backgroundColor: '#973131'}}>
                <h1> <EngineeringIcon style={{fontSize: '120px'}}/></h1>
                <p>Konstruksiya kurslarimizda siz binolarning qurilish konstruksiyalari, ularni armaturalash, metal va temir beton konstruksiyalarini loyihalashni mukammal darajada o’rganasiz.</p>
              </div>
              <div className="right" data-aos='fade-in' data-aos-duration='2000' style={{backgroundImage: `url(https://i.pinimg.com/564x/a2/08/ef/a208ef3cf05d7685c1c2d6862ad4a42a.jpg)`, backgroundSize: '100% 120%'}}></div>
            </div>

            <div className="box">
              <div className="right" data-aos='fade-in' data-aos-duration='2000'  style={{backgroundImage: `url(125.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
              <div className="left" style={{backgroundColor: '#973131'}}>
              <h1> <BuildIcon style={{fontSize: '120px'}}/></h1>
              <p>Injeneriya kurslarimizda siz binolar uchun ventilyatsiya tizimlari, suv quvurlari va isitish tizimlari hamda elektr energiya tizimlarini loyihalashni mukammal darajada o’rganasiz.</p>
              </div>
            </div>

            <div className="box">
              <div className="left" style={{backgroundColor: '#973131'}}>
                <h1> <RequestQuoteIcon style={{fontSize: '120px'}}/></h1>
                <p>Smeta kurslarimizda siz loyihalangan binolarning davlat tomonidan belgilangan normativlar asosida qurilishga ketadigan harajatlarni hisoblash ishlarini o’rganishingiz mumkin.</p>
              </div>
              <div className="right" data-aos='fade-in' data-aos-duration='2000' style={{backgroundImage: `url(smeta.jpg)`, backgroundSize: '100% 120%'}}></div>
            </div>
        </Box>
      </div>
    )
  }

export default Categories;
