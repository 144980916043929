import './announcement.scss'
import NotificationsIcon from '@mui/icons-material/Notifications';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Link } from 'react-router-dom';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const Announcement = () => {
    const announce = [
        {
            title: 'Smeta kursi yangi guruhga qabul boshlandi',
            date: '12.01.2024',
            picture: 'smeta.jpg',
        },
        {
            title: 'Qurilish konstruksiyalari (temir beton) yangi guruhga qabul boshlandi',
            date: '12.01.2024',
            picture: 'engi2.jpg',
        },
        
        
    ]

    const videos =[
        {
            title: 'Rasshot va loyihalash haqida mulohaza',
            url: '44I-zi9AB3s?si=tSifz2GQ98o7rqXq',
        },
        {
            title: 'Har bir konstruktor bilishi kerak',
            url: 'A025sRkwq_o?si=Kgmpmcu4iKwcytSq',
        },
        {
          title: 'Konstruktorlar uchun AutoCAD',
          url: 'jIDt74rdQW8?si=AXvc3ec5kyc2FSLD',
      },
      
    ]
    return (
      <div className='announcement'>
        <div className="content">
            <div className="left">
              <h4><NotificationsIcon style={{ height: '26px', width: '30px'}}/>E'lonlar</h4>
              {announce.map((key, index) => (
                <div className="announce" key={index} style={{backgroundImage: `url(${key.picture})`}} data-aos='fade-in' data-aos-duration='2000'>
                    <h5>{key.title}</h5>
                    <Link to={'https://t.me/Konstruktor_admin'} className='btn'>Batafsil</Link>
                </div>
              ))}
            </div>
            <div className="right">
              <h4><PriorityHighIcon style={{ height: '26px', width: '30px'}}/>Dolzarb suhbatlar</h4>
              {videos.map((key, index) => (
                <div className="video" key={index} data-aos='fade-up' data-aos-duration='2000'>
                  <iframe src={`https://www.youtube.com/embed/${key.url}`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
                  <h5><OndemandVideoIcon style={{ height: '26px', width: '30px'}}/>{key.title}</h5>
                </div>
              ))}
            </div>
        </div>
      </div>
    )
  }

  export default Announcement;
