import { Component } from 'react'
import './partners.scss'

export default class Partners extends Component {
  render() {
    return (
      <div>Partners</div>
    )
  }
}
