import { Component } from 'react'
import './login.scss'

export default class Login extends Component {
  render() {
    return (
      <div>Login</div>
    )
  }
}
