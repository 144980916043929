import { Component } from 'react'
import './contacts.scss'

export default class Contacts extends Component {
  render() {
    return (
      <div>Contacts</div>
    )
  }
}
