import './info.scss'
import { Box } from '@mui/material'
import CountUp from 'react-countup';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import RoofingIcon from '@mui/icons-material/Roofing';

const Info = ()=> {
    const infos = [
        {
            class: 'left',
            number: 5000,
            text: 'Amalga oshirib kelayotgan loyihalarimiz',
            icon: <RoofingIcon style={{fontSize: 50}}/>
        },
        {
            class: 'center',
            number: 500,
            text: 'Jami tahsil olayotgan o\'quvchilarimiz',
            icon: <AssignmentIndIcon style={{fontSize: 50}}/>
        },
        {
            class: 'right',
            number: 50,
            text: 'Kadrlar yetishtirayotgan tajribali ustozlar',
            icon: <PersonPinIcon style={{fontSize: 50}}/>
        }
    ]
    return (
      <div className='info'>
        <div className="content">
            {infos.map((key, index) => (
                <Box className={key.class} key={index}>
                    <h1><span>{key.icon}</span> <CountUp className="account-balance" start={0} end={key.number} decimals={0} duration={3} separator="" useEasing={true}/>+</h1>
                    <p>{key.text}</p>
                </Box>
            ))}

        </div>
      </div>
    )
}
export default Info;
