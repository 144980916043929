import './teachersCarousel.scss'

const TeachersCarousel = () => {
    return (
      <div className="teachersCarousel">
        <div className="content">
          <h4>Bizning tajribali jamoamiz</h4>
        </div>
        
      </div>
    )
  }

export default TeachersCarousel;
