import { Component } from 'react'
import './home.scss'
import Header from './Header/Header'
import Banner from './Banner/Banner'
import Categories from './Categories/Categories'
import Footer from './Footer/Footer'
import Info from './Info/Info'
import Announcement from './Announcement/Announcement'
import TeachersCarousel from './TeachersCarousel/TeachersCarousel'
import Partners from './Partners/Partners'
import Contacts from './Contacts/Contacts'


export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <Header />
        <Banner />
        <Info />
        <Categories />
        <Announcement />
        <TeachersCarousel />
        <Partners />
        <Contacts />
        <Footer />
      </div>
    )
  }
}
